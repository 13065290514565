/**
 * @fileoverview gRPC-Web generated client stub for advancedCharting_currency_pair_endpoint
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.1
// source: currency_pair.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as currency_pair_pb from './currency_pair_pb';


export class CurrencyPairEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetCurrencyPairs = new grpcWeb.MethodDescriptor(
    '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPairs',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.GetCurrencyPairsRequest,
    currency_pair_pb.GetCurrencyPairsResponse,
    (request: currency_pair_pb.GetCurrencyPairsRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.GetCurrencyPairsResponse.deserializeBinary
  );

  getCurrencyPairs(
    request: currency_pair_pb.GetCurrencyPairsRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.GetCurrencyPairsResponse>;

  getCurrencyPairs(
    request: currency_pair_pb.GetCurrencyPairsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairsResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.GetCurrencyPairsResponse>;

  getCurrencyPairs(
    request: currency_pair_pb.GetCurrencyPairsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPairs',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyPairs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPairs',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyPairs);
  }

  methodDescriptorGetCategories = new grpcWeb.MethodDescriptor(
    '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCategories',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.GetCategoriesRequest,
    currency_pair_pb.GetCategoriesResponse,
    (request: currency_pair_pb.GetCategoriesRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.GetCategoriesResponse.deserializeBinary
  );

  getCategories(
    request: currency_pair_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.GetCategoriesResponse>;

  getCategories(
    request: currency_pair_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCategoriesResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.GetCategoriesResponse>;

  getCategories(
    request: currency_pair_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCategories',
        request,
        metadata || {},
        this.methodDescriptorGetCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCategories',
    request,
    metadata || {},
    this.methodDescriptorGetCategories);
  }

  methodDescriptorGetCurrencyPair = new grpcWeb.MethodDescriptor(
    '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPair',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.GetCurrencyPairRequest,
    currency_pair_pb.GetCurrencyPairResponse,
    (request: currency_pair_pb.GetCurrencyPairRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.GetCurrencyPairResponse.deserializeBinary
  );

  getCurrencyPair(
    request: currency_pair_pb.GetCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.GetCurrencyPairResponse>;

  getCurrencyPair(
    request: currency_pair_pb.GetCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.GetCurrencyPairResponse>;

  getCurrencyPair(
    request: currency_pair_pb.GetCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPair',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyPair,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPair',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyPair);
  }

  methodDescriptorGetChart = new grpcWeb.MethodDescriptor(
    '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetChart',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.GetChartRequest,
    currency_pair_pb.GetChartResponse,
    (request: currency_pair_pb.GetChartRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.GetChartResponse.deserializeBinary
  );

  getChart(
    request: currency_pair_pb.GetChartRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.GetChartResponse>;

  getChart(
    request: currency_pair_pb.GetChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetChartResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.GetChartResponse>;

  getChart(
    request: currency_pair_pb.GetChartRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetChart',
        request,
        metadata || {},
        this.methodDescriptorGetChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/advancedCharting_currency_pair_endpoint.CurrencyPairEndpoint/GetChart',
    request,
    metadata || {},
    this.methodDescriptorGetChart);
  }

}

