/**
 * @fileoverview gRPC-Web generated client stub for sarafaClientPortal_report_endpoint
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.1
// source: report.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as report_pb from './report_pb';


export class ReportEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetAccountBalanceReport = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetAccountBalanceReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetAccountBalanceReportRequest,
    report_pb.GetAccountBalanceReportResponse,
    (request: report_pb.GetAccountBalanceReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetAccountBalanceReportResponse.deserializeBinary
  );

  getAccountBalanceReport(
    request: report_pb.GetAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetAccountBalanceReportResponse>;

  getAccountBalanceReport(
    request: report_pb.GetAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetAccountBalanceReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetAccountBalanceReportResponse>;

  getAccountBalanceReport(
    request: report_pb.GetAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetAccountBalanceReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetAccountBalanceReport',
        request,
        metadata || {},
        this.methodDescriptorGetAccountBalanceReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetAccountBalanceReport',
    request,
    metadata || {},
    this.methodDescriptorGetAccountBalanceReport);
  }

  methodDescriptorExportAccountBalanceReport = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_report_endpoint.ReportEndpoint/ExportAccountBalanceReport',
    grpcWeb.MethodType.UNARY,
    report_pb.ExportAccountBalanceReportRequest,
    report_pb.ExportAccountBalanceReportResponse,
    (request: report_pb.ExportAccountBalanceReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.ExportAccountBalanceReportResponse.deserializeBinary
  );

  exportAccountBalanceReport(
    request: report_pb.ExportAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.ExportAccountBalanceReportResponse>;

  exportAccountBalanceReport(
    request: report_pb.ExportAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.ExportAccountBalanceReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.ExportAccountBalanceReportResponse>;

  exportAccountBalanceReport(
    request: report_pb.ExportAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.ExportAccountBalanceReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_report_endpoint.ReportEndpoint/ExportAccountBalanceReport',
        request,
        metadata || {},
        this.methodDescriptorExportAccountBalanceReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_report_endpoint.ReportEndpoint/ExportAccountBalanceReport',
    request,
    metadata || {},
    this.methodDescriptorExportAccountBalanceReport);
  }

  methodDescriptorGetCurrencyExchangeRatesReport = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetCurrencyExchangeRatesReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetCurrencyExchangeRatesReportRequest,
    report_pb.GetCurrencyExchangeRatesReportResponse,
    (request: report_pb.GetCurrencyExchangeRatesReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetCurrencyExchangeRatesReportResponse.deserializeBinary
  );

  getCurrencyExchangeRatesReport(
    request: report_pb.GetCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetCurrencyExchangeRatesReportResponse>;

  getCurrencyExchangeRatesReport(
    request: report_pb.GetCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetCurrencyExchangeRatesReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetCurrencyExchangeRatesReportResponse>;

  getCurrencyExchangeRatesReport(
    request: report_pb.GetCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetCurrencyExchangeRatesReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetCurrencyExchangeRatesReport',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyExchangeRatesReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetCurrencyExchangeRatesReport',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyExchangeRatesReport);
  }

  methodDescriptorGetTradingReport = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetTradingReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetTradingReportRequest,
    report_pb.GetTradingReportResponse,
    (request: report_pb.GetTradingReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetTradingReportResponse.deserializeBinary
  );

  getTradingReport(
    request: report_pb.GetTradingReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetTradingReportResponse>;

  getTradingReport(
    request: report_pb.GetTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetTradingReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetTradingReportResponse>;

  getTradingReport(
    request: report_pb.GetTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetTradingReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetTradingReport',
        request,
        metadata || {},
        this.methodDescriptorGetTradingReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_report_endpoint.ReportEndpoint/GetTradingReport',
    request,
    metadata || {},
    this.methodDescriptorGetTradingReport);
  }

  methodDescriptorExportTradingReport = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_report_endpoint.ReportEndpoint/ExportTradingReport',
    grpcWeb.MethodType.UNARY,
    report_pb.ExportTradingReportRequest,
    report_pb.ExportTradingReportResponse,
    (request: report_pb.ExportTradingReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.ExportTradingReportResponse.deserializeBinary
  );

  exportTradingReport(
    request: report_pb.ExportTradingReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.ExportTradingReportResponse>;

  exportTradingReport(
    request: report_pb.ExportTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.ExportTradingReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.ExportTradingReportResponse>;

  exportTradingReport(
    request: report_pb.ExportTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.ExportTradingReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_report_endpoint.ReportEndpoint/ExportTradingReport',
        request,
        metadata || {},
        this.methodDescriptorExportTradingReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_report_endpoint.ReportEndpoint/ExportTradingReport',
    request,
    metadata || {},
    this.methodDescriptorExportTradingReport);
  }

}

