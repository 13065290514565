/**
 * @fileoverview gRPC-Web generated client stub for sarafaClientPortal_account_endpoint
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.1
// source: account.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_pb from './account_pb';


export class AccountEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorOpen = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_account_endpoint.AccountEndpoint/Open',
    grpcWeb.MethodType.UNARY,
    account_pb.OpenAccountRequest,
    account_pb.OpenAccountResponse,
    (request: account_pb.OpenAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.OpenAccountResponse.deserializeBinary
  );

  open(
    request: account_pb.OpenAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.OpenAccountResponse>;

  open(
    request: account_pb.OpenAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.OpenAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.OpenAccountResponse>;

  open(
    request: account_pb.OpenAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.OpenAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_account_endpoint.AccountEndpoint/Open',
        request,
        metadata || {},
        this.methodDescriptorOpen,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_account_endpoint.AccountEndpoint/Open',
    request,
    metadata || {},
    this.methodDescriptorOpen);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_account_endpoint.AccountEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateAccountRequest,
    account_pb.UpdateAccountResponse,
    (request: account_pb.UpdateAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateAccountResponse.deserializeBinary
  );

  update(
    request: account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateAccountResponse>;

  update(
    request: account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateAccountResponse>;

  update(
    request: account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_account_endpoint.AccountEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_account_endpoint.AccountEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorClose = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_account_endpoint.AccountEndpoint/Close',
    grpcWeb.MethodType.UNARY,
    account_pb.CloseAccountRequest,
    account_pb.CloseAccountResponse,
    (request: account_pb.CloseAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.CloseAccountResponse.deserializeBinary
  );

  close(
    request: account_pb.CloseAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.CloseAccountResponse>;

  close(
    request: account_pb.CloseAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.CloseAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.CloseAccountResponse>;

  close(
    request: account_pb.CloseAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.CloseAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_account_endpoint.AccountEndpoint/Close',
        request,
        metadata || {},
        this.methodDescriptorClose,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_account_endpoint.AccountEndpoint/Close',
    request,
    metadata || {},
    this.methodDescriptorClose);
  }

  methodDescriptorGetAccount = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetAccount',
    grpcWeb.MethodType.UNARY,
    account_pb.GetAccountRequest,
    account_pb.GetAccountResponse,
    (request: account_pb.GetAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.GetAccountResponse.deserializeBinary
  );

  getAccount(
    request: account_pb.GetAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.GetAccountResponse>;

  getAccount(
    request: account_pb.GetAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.GetAccountResponse>;

  getAccount(
    request: account_pb.GetAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetAccount',
        request,
        metadata || {},
        this.methodDescriptorGetAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetAccount',
    request,
    metadata || {},
    this.methodDescriptorGetAccount);
  }

  methodDescriptorGetAccounts = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetAccounts',
    grpcWeb.MethodType.UNARY,
    account_pb.GetAccountsRequest,
    account_pb.GetAccountsResponse,
    (request: account_pb.GetAccountsRequest) => {
      return request.serializeBinary();
    },
    account_pb.GetAccountsResponse.deserializeBinary
  );

  getAccounts(
    request: account_pb.GetAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.GetAccountsResponse>;

  getAccounts(
    request: account_pb.GetAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountsResponse) => void): grpcWeb.ClientReadableStream<account_pb.GetAccountsResponse>;

  getAccounts(
    request: account_pb.GetAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetAccounts',
        request,
        metadata || {},
        this.methodDescriptorGetAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetAccounts',
    request,
    metadata || {},
    this.methodDescriptorGetAccounts);
  }

  methodDescriptorGetOpenAccountConfigurations = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetOpenAccountConfigurations',
    grpcWeb.MethodType.UNARY,
    account_pb.GetOpenAccountConfigurationsRequest,
    account_pb.GetOpenAccountConfigurationsResponse,
    (request: account_pb.GetOpenAccountConfigurationsRequest) => {
      return request.serializeBinary();
    },
    account_pb.GetOpenAccountConfigurationsResponse.deserializeBinary
  );

  getOpenAccountConfigurations(
    request: account_pb.GetOpenAccountConfigurationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.GetOpenAccountConfigurationsResponse>;

  getOpenAccountConfigurations(
    request: account_pb.GetOpenAccountConfigurationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.GetOpenAccountConfigurationsResponse) => void): grpcWeb.ClientReadableStream<account_pb.GetOpenAccountConfigurationsResponse>;

  getOpenAccountConfigurations(
    request: account_pb.GetOpenAccountConfigurationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.GetOpenAccountConfigurationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetOpenAccountConfigurations',
        request,
        metadata || {},
        this.methodDescriptorGetOpenAccountConfigurations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_account_endpoint.AccountEndpoint/GetOpenAccountConfigurations',
    request,
    metadata || {},
    this.methodDescriptorGetOpenAccountConfigurations);
  }

  methodDescriptorUpdateTransferControl = new grpcWeb.MethodDescriptor(
    '/sarafaClientPortal_account_endpoint.AccountEndpoint/UpdateTransferControl',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateTransferControlRequest,
    account_pb.UpdateTransferControlResponse,
    (request: account_pb.UpdateTransferControlRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateTransferControlResponse.deserializeBinary
  );

  updateTransferControl(
    request: account_pb.UpdateTransferControlRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateTransferControlResponse>;

  updateTransferControl(
    request: account_pb.UpdateTransferControlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateTransferControlResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateTransferControlResponse>;

  updateTransferControl(
    request: account_pb.UpdateTransferControlRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateTransferControlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sarafaClientPortal_account_endpoint.AccountEndpoint/UpdateTransferControl',
        request,
        metadata || {},
        this.methodDescriptorUpdateTransferControl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sarafaClientPortal_account_endpoint.AccountEndpoint/UpdateTransferControl',
    request,
    metadata || {},
    this.methodDescriptorUpdateTransferControl);
  }

}

