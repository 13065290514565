// source: report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.AccountPeriod', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.Label', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.PricePeriod', null, global);
goog.exportSymbol('proto.sarafaClientPortal_report_endpoint.TradeingPeriod', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.displayName = 'proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.repeatedFields_, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.displayName = 'proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.displayName = 'proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.displayName = 'proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.repeatedFields_, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.displayName = 'proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.Label.displayName = 'proto.sarafaClientPortal_report_endpoint.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.AccountPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.AccountPeriod.displayName = 'proto.sarafaClientPortal_report_endpoint.AccountPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.displayName = 'proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.repeatedFields_, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.displayName = 'proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.PricePeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.PricePeriod.displayName = 'proto.sarafaClientPortal_report_endpoint.PricePeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.TradeingPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.TradeingPeriod.displayName = 'proto.sarafaClientPortal_report_endpoint.TradeingPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.repeatedFields_, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.displayName = 'proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.repeatedFields_, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.displayName = 'proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.displayName = 'proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.displayName = 'proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.oneofGroups_);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.displayName = 'proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.oneofGroups_);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.displayName = 'proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.oneofGroups_);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.displayName = 'proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.oneofGroups_);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.displayName = 'proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_);
};
goog.inherits(proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.displayName = 'proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest;
  return proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 groupBy = 4;
 * @return {number}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 4, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.sarafaClientPortal_report_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest;
  return proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 5:
      var value = new proto.sarafaClientPortal_report_endpoint.Label;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sarafaClientPortal_report_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 groupBy = 4;
 * @return {number}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Label labels = 5;
 * @return {!Array<!proto.sarafaClientPortal_report_endpoint.Label>}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.sarafaClientPortal_report_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sarafaClientPortal_report_endpoint.Label, 5));
};


/**
 * @param {!Array<!proto.sarafaClientPortal_report_endpoint.Label>} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sarafaClientPortal_report_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sarafaClientPortal_report_endpoint.Label}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sarafaClientPortal_report_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 6;
 * @return {number}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest;
  return proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string currencyPair = 1;
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue account = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 groupBy = 5;
 * @return {number}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: (f = msg.getGroupby()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest;
  return proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setGroupby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currencyPair = 1;
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value groupBy = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.getGroupby = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.clearGroupby = function() {
  return this.setGroupby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportRequest.prototype.hasGroupby = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    groupby: jspb.Message.getFieldWithDefault(msg, 5, 0),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.sarafaClientPortal_report_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest;
  return proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupby(value);
      break;
    case 6:
      var value = new proto.sarafaClientPortal_report_endpoint.Label;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.sarafaClientPortal_report_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string currencyPair = 1;
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue account = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 groupBy = 5;
 * @return {number}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.getGroupby = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Label labels = 6;
 * @return {!Array<!proto.sarafaClientPortal_report_endpoint.Label>}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.sarafaClientPortal_report_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sarafaClientPortal_report_endpoint.Label, 6));
};


/**
 * @param {!Array<!proto.sarafaClientPortal_report_endpoint.Label>} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sarafaClientPortal_report_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sarafaClientPortal_report_endpoint.Label}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sarafaClientPortal_report_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 7;
 * @return {number}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    propartyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelvalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.Label}
 */
proto.sarafaClientPortal_report_endpoint.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.Label;
  return proto.sarafaClientPortal_report_endpoint.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.Label}
 */
proto.sarafaClientPortal_report_endpoint.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropartyname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelvalue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropartyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelvalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string propartyName = 1;
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.getPropartyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sarafaClientPortal_report_endpoint.Label} returns this
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.setPropartyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string labelValue = 2;
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.getLabelvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sarafaClientPortal_report_endpoint.Label} returns this
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.setLabelvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue value = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.Label} returns this
*/
proto.sarafaClientPortal_report_endpoint.Label.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.Label} returns this
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.Label.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.AccountPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    openningbalance: (f = msg.getOpenningbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalcredit: (f = msg.getTotalcredit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totaldebit: (f = msg.getTotaldebit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closingbalance: (f = msg.getClosingbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofcreditoperations: (f = msg.getTotalnumberofcreditoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalnumberofdebitoperations: (f = msg.getTotalnumberofdebitoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.AccountPeriod;
  return proto.sarafaClientPortal_report_endpoint.AccountPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpenningbalance(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalcredit(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotaldebit(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosingbalance(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofcreditoperations(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofdebitoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.AccountPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOpenningbalance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalcredit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotaldebit();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClosingbalance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofcreditoperations();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofdebitoperations();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue openningBalance = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.getOpenningbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.setOpenningbalance = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.clearOpenningbalance = function() {
  return this.setOpenningbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.hasOpenningbalance = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalCredit = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.getTotalcredit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.setTotalcredit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.clearTotalcredit = function() {
  return this.setTotalcredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.hasTotalcredit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue totalDebit = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.getTotaldebit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.setTotaldebit = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.clearTotaldebit = function() {
  return this.setTotaldebit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.hasTotaldebit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue closingBalance = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.getClosingbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.setClosingbalance = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.clearClosingbalance = function() {
  return this.setClosingbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.hasClosingbalance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfCreditOperations = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.getTotalnumberofcreditoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.setTotalnumberofcreditoperations = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.clearTotalnumberofcreditoperations = function() {
  return this.setTotalnumberofcreditoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.hasTotalnumberofcreditoperations = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfDebitOperations = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.getTotalnumberofdebitoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.setTotalnumberofdebitoperations = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.clearTotalnumberofdebitoperations = function() {
  return this.setTotalnumberofdebitoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.AccountPeriod.prototype.hasTotalnumberofdebitoperations = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: msg.getReport_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay;
  return proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes report = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.getReport = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes report = 1;
 * This is a type-conversion wrapper around `getReport()`
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.getReport_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReport()));
};


/**
 * optional bytes report = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReport()`
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.getReport_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReport()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.setReport = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.sarafaClientPortal_report_endpoint.AccountPeriod.toObject, includeInstance),
    totalcredit: (f = msg.getTotalcredit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totaldebit: (f = msg.getTotaldebit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalnumberofcreditoperations: (f = msg.getTotalnumberofcreditoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalnumberofdebitoperations: (f = msg.getTotalnumberofdebitoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay;
  return proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.AccountPeriod;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.AccountPeriod.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalcredit(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotaldebit(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofcreditoperations(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalnumberofdebitoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.AccountPeriod.serializeBinaryToWriter
    );
  }
  f = message.getTotalcredit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotaldebit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofcreditoperations();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalnumberofdebitoperations();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountPeriod report = 1;
 * @return {!Array<!proto.sarafaClientPortal_report_endpoint.AccountPeriod>}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.getReportList = function() {
  return /** @type{!Array<!proto.sarafaClientPortal_report_endpoint.AccountPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sarafaClientPortal_report_endpoint.AccountPeriod, 1));
};


/**
 * @param {!Array<!proto.sarafaClientPortal_report_endpoint.AccountPeriod>} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sarafaClientPortal_report_endpoint.AccountPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sarafaClientPortal_report_endpoint.AccountPeriod}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sarafaClientPortal_report_endpoint.AccountPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional google.protobuf.StringValue totalCredit = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.getTotalcredit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.setTotalcredit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotalcredit = function() {
  return this.setTotalcredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotalcredit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalDebit = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.getTotaldebit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.setTotaldebit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotaldebit = function() {
  return this.setTotaldebit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotaldebit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfCreditOperations = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.getTotalnumberofcreditoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.setTotalnumberofcreditoperations = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotalnumberofcreditoperations = function() {
  return this.setTotalnumberofcreditoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotalnumberofcreditoperations = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value totalNumberOfDebitOperations = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.getTotalnumberofdebitoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.setTotalnumberofdebitoperations = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.clearTotalnumberofdebitoperations = function() {
  return this.setTotalnumberofdebitoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.prototype.hasTotalnumberofdebitoperations = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.PricePeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.PricePeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    closesellprice: (f = msg.getClosesellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closebuyprice: (f = msg.getClosebuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closeavgprice: (f = msg.getCloseavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closetradingprice: (f = msg.getClosetradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    opensellprice: (f = msg.getOpensellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    openbuyprice: (f = msg.getOpenbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    openavgprice: (f = msg.getOpenavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    opentradingprice: (f = msg.getOpentradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highestavgprice: (f = msg.getHighestavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsetavgprice: (f = msg.getLowsetavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highestsellprice: (f = msg.getHighestsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsetsellprice: (f = msg.getLowsetsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highestbuyprice: (f = msg.getHighestbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsetbuyprice: (f = msg.getLowsetbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highesttradingprice: (f = msg.getHighesttradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsettradingprice: (f = msg.getLowsettradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.PricePeriod;
  return proto.sarafaClientPortal_report_endpoint.PricePeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.PricePeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosesellprice(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosebuyprice(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCloseavgprice(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosetradingprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpensellprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpenbuyprice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpenavgprice(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpentradingprice(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighestavgprice(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsetavgprice(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighestsellprice(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsetsellprice(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighestbuyprice(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsetbuyprice(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighesttradingprice(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsettradingprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.PricePeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.PricePeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getClosesellprice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClosebuyprice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCloseavgprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClosetradingprice();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpensellprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpenbuyprice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpenavgprice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpentradingprice();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighestavgprice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsetavgprice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighestsellprice();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsetsellprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighestbuyprice();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsetbuyprice();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighesttradingprice();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsettradingprice();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue closeSellPrice = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getClosesellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setClosesellprice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearClosesellprice = function() {
  return this.setClosesellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasClosesellprice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue closeBuyPrice = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getClosebuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setClosebuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearClosebuyprice = function() {
  return this.setClosebuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasClosebuyprice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue closeAvgPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getCloseavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setCloseavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearCloseavgprice = function() {
  return this.setCloseavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasCloseavgprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue closeTradingPrice = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getClosetradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setClosetradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearClosetradingprice = function() {
  return this.setClosetradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasClosetradingprice = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue openSellPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getOpensellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setOpensellprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearOpensellprice = function() {
  return this.setOpensellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasOpensellprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue openBuyPrice = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getOpenbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setOpenbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearOpenbuyprice = function() {
  return this.setOpenbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasOpenbuyprice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue openAvgPrice = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getOpenavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setOpenavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearOpenavgprice = function() {
  return this.setOpenavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasOpenavgprice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue openTradingPrice = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getOpentradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setOpentradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearOpentradingprice = function() {
  return this.setOpentradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasOpentradingprice = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.StringValue highestAvgPrice = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getHighestavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setHighestavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearHighestavgprice = function() {
  return this.setHighestavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasHighestavgprice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue lowsetAvgPrice = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getLowsetavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setLowsetavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearLowsetavgprice = function() {
  return this.setLowsetavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasLowsetavgprice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue highestSellPrice = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getHighestsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setHighestsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearHighestsellprice = function() {
  return this.setHighestsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasHighestsellprice = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue lowsetSellPrice = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getLowsetsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setLowsetsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearLowsetsellprice = function() {
  return this.setLowsetsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasLowsetsellprice = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue highestBuyPrice = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getHighestbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setHighestbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearHighestbuyprice = function() {
  return this.setHighestbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasHighestbuyprice = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue lowsetBuyPrice = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getLowsetbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setLowsetbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearLowsetbuyprice = function() {
  return this.setLowsetbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasLowsetbuyprice = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue highestTradingPrice = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getHighesttradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setHighesttradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearHighesttradingprice = function() {
  return this.setHighesttradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasHighesttradingprice = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue lowsetTradingPrice = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.getLowsettradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.setLowsettradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.clearLowsettradingprice = function() {
  return this.setLowsettradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.PricePeriod.prototype.hasLowsettradingprice = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.TradeingPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    totalsold: (f = msg.getTotalsold()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalbought: (f = msg.getTotalbought()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    avgbuyprice: (f = msg.getAvgbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    avgsellprice: (f = msg.getAvgsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numberofselloperations: (f = msg.getNumberofselloperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    numberofbuyoperations: (f = msg.getNumberofbuyoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.TradeingPeriod;
  return proto.sarafaClientPortal_report_endpoint.TradeingPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalsold(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalbought(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAvgbuyprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAvgsellprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setNumberofselloperations(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setNumberofbuyoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.TradeingPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTotalsold();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalbought();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAvgbuyprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAvgsellprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumberofselloperations();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getNumberofbuyoperations();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue totalSold = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.getTotalsold = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.setTotalsold = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.clearTotalsold = function() {
  return this.setTotalsold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.hasTotalsold = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalBought = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.getTotalbought = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.setTotalbought = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.clearTotalbought = function() {
  return this.setTotalbought(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.hasTotalbought = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue avgBuyPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.getAvgbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.setAvgbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.clearAvgbuyprice = function() {
  return this.setAvgbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.hasAvgbuyprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue avgSellPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.getAvgsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.setAvgsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.clearAvgsellprice = function() {
  return this.setAvgsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.hasAvgsellprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value numberOfSellOperations = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.getNumberofselloperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.setNumberofselloperations = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.clearNumberofselloperations = function() {
  return this.setNumberofselloperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.hasNumberofselloperations = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value numberOfBuyOperations = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.getNumberofbuyoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
*/
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.setNumberofbuyoperations = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod} returns this
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.clearNumberofbuyoperations = function() {
  return this.setNumberofbuyoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.TradeingPeriod.prototype.hasNumberofbuyoperations = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.sarafaClientPortal_report_endpoint.PricePeriod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay;
  return proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.PricePeriod;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.PricePeriod.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.PricePeriod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PricePeriod report = 1;
 * @return {!Array<!proto.sarafaClientPortal_report_endpoint.PricePeriod>}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.prototype.getReportList = function() {
  return /** @type{!Array<!proto.sarafaClientPortal_report_endpoint.PricePeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sarafaClientPortal_report_endpoint.PricePeriod, 1));
};


/**
 * @param {!Array<!proto.sarafaClientPortal_report_endpoint.PricePeriod>} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sarafaClientPortal_report_endpoint.PricePeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sarafaClientPortal_report_endpoint.PricePeriod}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sarafaClientPortal_report_endpoint.PricePeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.prototype.clearReportList = function() {
  return this.setReportList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.sarafaClientPortal_report_endpoint.TradeingPeriod.toObject, includeInstance),
    totalsold: (f = msg.getTotalsold()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    totalbought: (f = msg.getTotalbought()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    avgbuyprice: (f = msg.getAvgbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    avgsellprice: (f = msg.getAvgsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numberofselloperations: (f = msg.getNumberofselloperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    numberofbuyoperations: (f = msg.getNumberofbuyoperations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay;
  return proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.TradeingPeriod;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.TradeingPeriod.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalsold(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalbought(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAvgbuyprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAvgsellprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setNumberofselloperations(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setNumberofbuyoperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.TradeingPeriod.serializeBinaryToWriter
    );
  }
  f = message.getTotalsold();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTotalbought();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAvgbuyprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAvgsellprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumberofselloperations();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getNumberofbuyoperations();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TradeingPeriod report = 1;
 * @return {!Array<!proto.sarafaClientPortal_report_endpoint.TradeingPeriod>}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.getReportList = function() {
  return /** @type{!Array<!proto.sarafaClientPortal_report_endpoint.TradeingPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sarafaClientPortal_report_endpoint.TradeingPeriod, 1));
};


/**
 * @param {!Array<!proto.sarafaClientPortal_report_endpoint.TradeingPeriod>} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sarafaClientPortal_report_endpoint.TradeingPeriod}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sarafaClientPortal_report_endpoint.TradeingPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.clearReportList = function() {
  return this.setReportList([]);
};


/**
 * optional google.protobuf.StringValue totalSold = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.getTotalsold = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.setTotalsold = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.clearTotalsold = function() {
  return this.setTotalsold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.hasTotalsold = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue totalBought = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.getTotalbought = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.setTotalbought = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.clearTotalbought = function() {
  return this.setTotalbought(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.hasTotalbought = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue avgBuyPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.getAvgbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.setAvgbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.clearAvgbuyprice = function() {
  return this.setAvgbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.hasAvgbuyprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue avgSellPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.getAvgsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.setAvgsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.clearAvgsellprice = function() {
  return this.setAvgsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.hasAvgsellprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value numberOfSellOperations = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.getNumberofselloperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.setNumberofselloperations = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.clearNumberofselloperations = function() {
  return this.setNumberofselloperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.hasNumberofselloperations = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value numberOfBuyOperations = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.getNumberofbuyoperations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.setNumberofbuyoperations = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.clearNumberofbuyoperations = function() {
  return this.setNumberofbuyoperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.prototype.hasNumberofbuyoperations = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: msg.getReport_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay;
  return proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes report = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.getReport = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes report = 1;
 * This is a type-conversion wrapper around `getReport()`
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.getReport_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReport()));
};


/**
 * optional bytes report = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReport()`
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.getReport_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReport()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.setReport = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportCurrencyExchangeRatesReportReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: msg.getReport_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay;
  return proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReport(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes report = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.getReport = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes report = 1;
 * This is a type-conversion wrapper around `getReport()`
 * @return {string}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.getReport_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReport()));
};


/**
 * optional bytes report = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReport()`
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.getReport_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReport()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.setReport = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.ResultCase}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse;
  return proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyExchangeRatesReportReplay success = 1;
 * @return {?proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay, 1));
};


/**
 * @param {?proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportReplay|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetCurrencyExchangeRatesReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.ResultCase}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse;
  return proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTradingReportReplay success = 1;
 * @return {?proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay, 1));
};


/**
 * @param {?proto.sarafaClientPortal_report_endpoint.GetTradingReportReplay|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetTradingReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.ResultCase}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse;
  return proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportTradingReportReplay success = 1;
 * @return {?proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay, 1));
};


/**
 * @param {?proto.sarafaClientPortal_report_endpoint.ExportTradingReportReplay|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportTradingReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.ResultCase}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse;
  return proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountBalanceReportReplay success = 1;
 * @return {?proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay, 1));
};


/**
 * @param {?proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportReplay|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse} returns this
*/
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse} returns this
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.GetAccountBalanceReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.ResultCase}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse;
  return proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay;
      reader.readMessage(value,proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportAccountBalanceReportReplay success = 1;
 * @return {?proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.prototype.getSuccess = function() {
  return /** @type{?proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay} */ (
    jspb.Message.getWrapperField(this, proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay, 1));
};


/**
 * @param {?proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportReplay|undefined} value
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse} returns this
*/
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse} returns this
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sarafaClientPortal_report_endpoint.ExportAccountBalanceReportResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.sarafaClientPortal_report_endpoint);
