// source: authentication.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.AccessToken', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.LogoutReply', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.Organization', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.Service', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.SessionInfo', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.Subscription', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.ResultCase', null, global);
goog.exportSymbol('proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.Organization.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.AccessToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.AccessToken.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.AccessToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dimensionsClientPortal_authentication_endpoint.Subscription.repeatedFields_, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.Subscription.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.Service = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.Service, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.Service.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.Service';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.LogoutReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.LogoutReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.repeatedFields_, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.SessionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.SessionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.oneofGroups_);
};
goog.inherits(proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.displayName = 'proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    captchatoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptchatoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaptchatoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string captchaToken = 2;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.prototype.getCaptchatoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordRequest.prototype.setCaptchatoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 sessionId = 1;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.prototype.getSessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutRequest.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isemailtfenabled: (f = msg.getIsemailtfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isphonetfenabled: (f = msg.getIsphonetfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isauthapptfenabled: (f = msg.getIsauthapptfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    tfemail: (f = msg.getTfemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tfphonenumber: (f = msg.getTfphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo;
  return proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsemailtfenabled(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsphonetfenabled(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsauthapptfenabled(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfemail(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfphonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsemailtfenabled();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsphonetfenabled();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsauthapptfenabled();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getTfemail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTfphonenumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue isEmailTFEnabled = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.getIsemailtfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.setIsemailtfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.clearIsemailtfenabled = function() {
  return this.setIsemailtfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.hasIsemailtfenabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isPhoneTFEnabled = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.getIsphonetfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.setIsphonetfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.clearIsphonetfenabled = function() {
  return this.setIsphonetfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.hasIsphonetfenabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAuthAppTFEnabled = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.getIsauthapptfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.setIsauthapptfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.clearIsauthapptfenabled = function() {
  return this.setIsauthapptfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.hasIsauthapptfenabled = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue TFEmail = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.getTfemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.setTfemail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.clearTfemail = function() {
  return this.setTfemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.hasTfemail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue TFPhoneNumber = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.getTfphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.setTfphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.clearTfphonenumber = function() {
  return this.setTfphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.prototype.hasTfphonenumber = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    captchatoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptchatoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaptchatoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string captchaToken = 2;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.prototype.getCaptchatoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordRequest.prototype.setCaptchatoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, ""),
    repassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRepassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rePassword = 2;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.prototype.getRepassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordRequest.prototype.setRepassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    refreshtoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefreshtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string refreshToken = 1;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.getRefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 sessionId = 2;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.getSessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string FCMtoken = 3;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenRequest.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    captchatoken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptchatoken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaptchatoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string captchaToken = 3;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.getCaptchatoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.setCaptchatoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string FCMtoken = 4;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateRequest.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    signature: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    refreshtokenexpiration: (f = msg.getRefreshtokenexpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    refreshtokenmetadata: jspb.Message.getFieldWithDefault(msg, 5, ""),
    secondcode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest;
  return proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCodeid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRefreshtokenexpiration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshtokenmetadata(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondcode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRefreshtokenexpiration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRefreshtokenmetadata();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSecondcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 codeId = 1;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.getCodeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.setCodeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string signature = 2;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp refreshTokenExpiration = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.getRefreshtokenexpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.setRefreshtokenexpiration = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.clearRefreshtokenexpiration = function() {
  return this.setRefreshtokenexpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.hasRefreshtokenexpiration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string RefreshTokenMetaData = 5;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.getRefreshtokenmetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.setRefreshtokenmetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string secondCode = 6;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.getSecondcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.setSecondcode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string FCMtoken = 7;
 * @return {string}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationRequest.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    domain: (f = msg.getDomain()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subscription: (f = msg.getSubscription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.Organization;
  return proto.dimensionsClientPortal_authentication_endpoint.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDomain(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDomain();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue domain = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.getDomain = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.setDomain = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.clearDomain = function() {
  return this.setDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.hasDomain = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue subscription = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.getSubscription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Organization} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Organization.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.AccessToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    refreshtoken: (f = msg.getRefreshtoken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    expirationdate: (f = msg.getExpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.AccessToken;
  return proto.dimensionsClientPortal_authentication_endpoint.AccessToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRefreshtoken(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.AccessToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRefreshtoken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExpirationdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 sessionId = 1;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.getSessionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue refreshToken = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.getRefreshtoken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.setRefreshtoken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.clearRefreshtoken = function() {
  return this.setRefreshtoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.hasRefreshtoken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue token = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.hasToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp expirationDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.getExpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.setExpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AccessToken} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.clearExpirationdate = function() {
  return this.setExpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AccessToken.prototype.hasExpirationdate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    accesstoken: (f = msg.getAccesstoken()) && proto.dimensionsClientPortal_authentication_endpoint.AccessToken.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && proto.dimensionsClientPortal_authentication_endpoint.Organization.toObject(includeInstance, f),
    passwordexpirationdate: (f = msg.getPasswordexpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    accountsecurity: (f = msg.getAccountsecurity()) && proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.toObject(includeInstance, f),
    clientsubscription: (f = msg.getClientsubscription()) && proto.dimensionsClientPortal_authentication_endpoint.Subscription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply;
  return proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 6:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.AccessToken;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.AccessToken.deserializeBinaryFromReader);
      msg.setAccesstoken(value);
      break;
    case 8:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.Organization;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPasswordexpirationdate(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    case 12:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.deserializeBinaryFromReader);
      msg.setAccountsecurity(value);
      break;
    case 13:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.Subscription;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.Subscription.deserializeBinaryFromReader);
      msg.setClientsubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccesstoken();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.AccessToken.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.Organization.serializeBinaryToWriter
    );
  }
  f = message.getPasswordexpirationdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountsecurity();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo.serializeBinaryToWriter
    );
  }
  f = message.getClientsubscription();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional dimensionsClientPortal_common.ProfileResponse profile = 5;
 * @return {?proto.dimensionsClientPortal_common.ProfileResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getProfile = function() {
  return /** @type{?proto.dimensionsClientPortal_common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.dimensionsClientPortal_common.ProfileResponse|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AccessToken accessToken = 6;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.AccessToken}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getAccesstoken = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.AccessToken} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.AccessToken, 6));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.AccessToken|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setAccesstoken = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearAccesstoken = function() {
  return this.setAccesstoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasAccesstoken = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Organization organization = 8;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.Organization}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getOrganization = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.Organization} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.Organization, 8));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.Organization|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp passwordExpirationDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getPasswordexpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setPasswordexpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearPasswordexpirationdate = function() {
  return this.setPasswordexpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasPasswordexpirationdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 11;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional AccountSecurityInfo accountSecurity = 12;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getAccountsecurity = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo, 12));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.AccountSecurityInfo|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setAccountsecurity = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearAccountsecurity = function() {
  return this.setAccountsecurity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasAccountsecurity = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Subscription clientSubscription = 13;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.Subscription}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.getClientsubscription = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.Subscription} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.Subscription, 13));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.Subscription|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.setClientsubscription = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.clearClientsubscription = function() {
  return this.setClientsubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.prototype.hasClientsubscription = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabledservicesList: jspb.Message.toObjectList(msg.getEnabledservicesList(),
    proto.dimensionsClientPortal_authentication_endpoint.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Subscription}
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.Subscription;
  return proto.dimensionsClientPortal_authentication_endpoint.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Subscription}
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.Service;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.Service.deserializeBinaryFromReader);
      msg.addEnabledservices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabledservicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.Service.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Service enabledServices = 2;
 * @return {!Array<!proto.dimensionsClientPortal_authentication_endpoint.Service>}
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.prototype.getEnabledservicesList = function() {
  return /** @type{!Array<!proto.dimensionsClientPortal_authentication_endpoint.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.Service, 2));
};


/**
 * @param {!Array<!proto.dimensionsClientPortal_authentication_endpoint.Service>} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Subscription} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Subscription.prototype.setEnabledservicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service}
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.prototype.addEnabledservices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dimensionsClientPortal_authentication_endpoint.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Subscription} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Subscription.prototype.clearEnabledservicesList = function() {
  return this.setEnabledservicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.Service.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Service} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    activationdate: (f = msg.getActivationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirationdate: (f = msg.getExpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    remaining: (f = msg.getRemaining()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.Service;
  return proto.dimensionsClientPortal_authentication_endpoint.Service.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Service} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActivationdate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationdate(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setRemaining(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.Service.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.Service} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActivationdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirationdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRemaining();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp activationDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.getActivationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.setActivationdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.clearActivationdate = function() {
  return this.setActivationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.hasActivationdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp expirationDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.getExpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.setExpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.clearExpirationdate = function() {
  return this.setExpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.hasExpirationdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue referenceTransaction = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.getReferencetransaction = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.DoubleValue remaining = 5;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.getRemaining = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 5));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.setRemaining = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.Service} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.clearRemaining = function() {
  return this.setRemaining(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.Service.prototype.hasRemaining = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.LogoutReply;
  return proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional dimensionsClientPortal_common.MessageResponse message = 2;
 * @return {?proto.dimensionsClientPortal_common.MessageResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.getMessage = function() {
  return /** @type{?proto.dimensionsClientPortal_common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.dimensionsClientPortal_common.MessageResponse|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: (f = msg.getAccesstoken()) && Protos_common_pb.AnonymousAccessToken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply;
  return proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.AnonymousAccessToken;
      reader.readMessage(value,Protos_common_pb.AnonymousAccessToken.deserializeBinaryFromReader);
      msg.setAccesstoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.AnonymousAccessToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional dimensionsClientPortal_common.AnonymousAccessToken accessToken = 1;
 * @return {?proto.dimensionsClientPortal_common.AnonymousAccessToken}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.prototype.getAccesstoken = function() {
  return /** @type{?proto.dimensionsClientPortal_common.AnonymousAccessToken} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AnonymousAccessToken, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_common.AnonymousAccessToken|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.prototype.setAccesstoken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.prototype.clearAccesstoken = function() {
  return this.setAccesstoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.prototype.hasAccesstoken = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: (f = msg.getAccesstoken()) && Protos_common_pb.AnonymousAccessToken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply;
  return proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.AnonymousAccessToken;
      reader.readMessage(value,Protos_common_pb.AnonymousAccessToken.deserializeBinaryFromReader);
      msg.setAccesstoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.AnonymousAccessToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional dimensionsClientPortal_common.AnonymousAccessToken accessToken = 1;
 * @return {?proto.dimensionsClientPortal_common.AnonymousAccessToken}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.prototype.getAccesstoken = function() {
  return /** @type{?proto.dimensionsClientPortal_common.AnonymousAccessToken} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AnonymousAccessToken, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_common.AnonymousAccessToken|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.prototype.setAccesstoken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.prototype.clearAccesstoken = function() {
  return this.setAccesstoken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.prototype.hasAccesstoken = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply;
  return proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional dimensionsClientPortal_common.MessageResponse message = 2;
 * @return {?proto.dimensionsClientPortal_common.MessageResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.getMessage = function() {
  return /** @type{?proto.dimensionsClientPortal_common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.dimensionsClientPortal_common.MessageResponse|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangePasswordReply success = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.prototype.getSuccess = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.ChangePasswordResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConfirmForgotPasswordReply success = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.prototype.getSuccess = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.ConfirmForgotPasswordResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional ForgotPasswordReply success = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.prototype.getSuccess = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.ForgotPasswordResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay;
  return proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.SessionInfo;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SessionInfo sessions = 1;
 * @return {!Array<!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo>}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.SessionInfo, 1));
};


/**
 * @param {!Array<!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo>} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.prototype.setSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dimensionsClientPortal_authentication_endpoint.SessionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    useragent: (f = msg.getUseragent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    clientipaddress: (f = msg.getClientipaddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    refreshtokenexpiration: (f = msg.getRefreshtokenexpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    browser: (f = msg.getBrowser()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    devicetype: (f = msg.getDevicetype()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.SessionInfo;
  return proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUseragent(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClientipaddress(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRefreshtokenexpiration(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBrowser(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDevicetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUseragent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClientipaddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRefreshtokenexpiration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBrowser();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDevicetype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue userAgent = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getUseragent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setUseragent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.clearUseragent = function() {
  return this.setUseragent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.hasUseragent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue clientIpAddress = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getClientipaddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setClientipaddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.clearClientipaddress = function() {
  return this.setClientipaddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.hasClientipaddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp refreshTokenExpiration = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getRefreshtokenexpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setRefreshtokenexpiration = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.clearRefreshtokenexpiration = function() {
  return this.setRefreshtokenexpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.hasRefreshtokenexpiration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue device = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getDevice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue browser = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getBrowser = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setBrowser = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.clearBrowser = function() {
  return this.setBrowser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.hasBrowser = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value deviceType = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.getDevicetype = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.setDevicetype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.SessionInfo} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.clearDevicetype = function() {
  return this.setDevicetype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.SessionInfo.prototype.hasDevicetype = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    signature: (f = msg.getSignature()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    maxattemptsnumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply;
  return proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCodeid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSignature(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxattemptsnumber(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSignature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMaxattemptsnumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 codeId = 1;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.getCodeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.setCodeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue signature = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.getSignature = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.setSignature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.clearSignature = function() {
  return this.setSignature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 maxAttemptsNumber = 3;
 * @return {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.getMaxattemptsnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.setMaxattemptsnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  AUTHENTICATE: 1,
  TWOFACTORENABLED: 2
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticate: (f = msg.getAuthenticate()) && proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.toObject(includeInstance, f),
    twofactorenabled: (f = msg.getTwofactorenabled()) && proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader);
      msg.setAuthenticate(value);
      break;
    case 2:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.deserializeBinaryFromReader);
      msg.setTwofactorenabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.serializeBinaryToWriter
    );
  }
  f = message.getTwofactorenabled();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthenticateReply authenticate = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.getAuthenticate = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.setAuthenticate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.clearAuthenticate = function() {
  return this.setAuthenticate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.hasAuthenticate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TwoFactorEnabledReply twoFactorEnabled = 2;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.getTwofactorenabled = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply, 2));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.TwoFactorEnabledReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.setTwofactorenabled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.clearTwofactorenabled = function() {
  return this.setTwofactorenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.AuthenticateResponse.prototype.hasTwofactorenabled = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  AUTHENTICATE: 1
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticate: (f = msg.getAuthenticate()) && proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader);
      msg.setAuthenticate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthenticateReply authenticate = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.prototype.getAuthenticate = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.prototype.setAuthenticate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.prototype.clearAuthenticate = function() {
  return this.setAuthenticate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.TwoFactorConfirmationResponse.prototype.hasAuthenticate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  AUTHENTICATE: 1
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticate: (f = msg.getAuthenticate()) && proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.deserializeBinaryFromReader);
      msg.setAuthenticate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthenticateReply authenticate = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.prototype.getAuthenticate = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.AuthenticateReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.prototype.setAuthenticate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.prototype.clearAuthenticate = function() {
  return this.setAuthenticate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.RefreshTokenResponse.prototype.hasAuthenticate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.LogoutReply;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.LogoutReply.serializeBinaryToWriter
    );
  }
};


/**
 * optional LogoutReply success = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.LogoutReply}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.prototype.getSuccess = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.LogoutReply} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.LogoutReply, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.LogoutReply|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.LogoutResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.ResultCase}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse;
  return proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay;
      reader.readMessage(value,proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetSessionsReplay success = 1;
 * @return {?proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay} */ (
    jspb.Message.getWrapperField(this, proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay, 1));
};


/**
 * @param {?proto.dimensionsClientPortal_authentication_endpoint.GetSessionsReplay|undefined} value
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse} returns this
*/
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse} returns this
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dimensionsClientPortal_authentication_endpoint.GetSessionsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.dimensionsClientPortal_authentication_endpoint);
